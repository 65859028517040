// const YouTubeIframeLoader = require('youtube-iframe')

import { klass } from './common'

const frontPageSetting = () => {
  const youtubeID = document.querySelector('html').getAttribute('data-youtube-id')

  if (youtubeID) {
    const wrapper = document.createElement('div')
    const video = document.createElement('div')
    wrapper.setAttribute('id', 'bg-video-wrapper')
    video.setAttribute('id', 'bg-video')

    wrapper.prepend(video)

    document.querySelector('body').prepend(wrapper)
    window.addEventListener('load', () => {
      youtubeAutoPlay('bg-video', youtubeID)
    })
  }
}

/**
 * YOUTUBE 表示
 * @param target : Target ID
 * @param id : Youtube ID
 */
const youtubeAutoPlay = (target, id) => {
  let player

  const onYouTubeIframeAPIReady = () => {
    player = new YT.Player(target, {
      videoId: id,
      playerVars: {
        playsinline: 1,
        start: 1,
        loop: 1,
        listType: 'playlist',
        playlist: id,
        rel: 0,
        controls: 0,
      },
      events: {
        onReady: onPlayerReady,
        onStateChange: onPlayerStateChange,
      },
    })
  }

  //プレーヤの準備完了後に呼び出す関数
  const onPlayerReady = (event) => {
    event.target.mute() //ミュートにしないとスマホで再生されない
    event.target.playVideo() //ビデオを再生
  }

  const onPlayerStateChange = (event) => {
    let ytStatus = event.target.getPlayerState()
    if (ytStatus === YT.PlayerState.ENDED) {
      event.target.mute() //ミュートにしないとスマホで再生されない
      event.target.playVideo() //ビデオを再生
    }
    if (event.data === 1) {
      // document.querySelector('.top-mv__cover').classList.add('is-hide')
    }
  }

  onYouTubeIframeAPIReady()
}

export { frontPageSetting }
