import { InitSetting, klass } from './modules/common'
// import Swiper from 'swiper/bundle'

/**
 * DOM読み込み完了時イベント
 */
document.addEventListener('DOMContentLoaded', () => {
  // Youtube読み込み準備
  if (document.querySelector('html').classList.contains('is-front-page')) {
    const tag = document.createElement('script')
    tag.src = 'https://www.youtube.com/iframe_api'
    const firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
  }

  window.taw = new InitSetting()
})

/**
 * ページ読み込み完了時イベント
 */
window.addEventListener('load', () => {
  document.querySelector('.loader').classList.add(klass.hidden)
})
