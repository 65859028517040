import { setUserAgent } from './ua'
import smoothscroll from 'smoothscroll-polyfill'
import inView from 'in-view'
import { frontPageSetting } from './frontPage'
import { lightboxSetting } from './lightbox'
import { swiperSettings } from './slider'

/**
 * 共通クラス名
 */
const klass = {
  active: 'is-active',
  fixed: 'is-fixed',
  view: 'is-view',
  selected: 'is-selected',
  scroll: 'is-scroll',
  hidden: 'is-hidden',
  eventNone: 'is-eventNone',
  landscape: 'is-landscape',
  current: 'is-current',
  nav: 'view-nav',
}

class InitSetting {
  constructor() {
    this.body = document.querySelector('body')
    setUserAgent()
    this.navigationSetting()
    this.scrollSetting()
    this.smoothScrollSetting()
    this.setInView()

    frontPageSetting()
    lightboxSetting()
    swiperSettings()
  }

  /**
   * ナビゲーションの開閉
   */
  navigationSetting() {
    // ナビゲーション開閉
    const trigger = document.querySelector('.nav-trigger')
    if (trigger) {
      trigger.addEventListener('click', () => {
        this.body.classList.toggle(klass.nav)
      })
    }

    // ナビゲーションを押下時に閉じる
    const navigationLink = Array.from(document.querySelectorAll('.gNav a'))
    if (navigationLink.length > 0) {
      navigationLink.forEach((trigger) => {
        trigger.addEventListener('click', () => {
          this.body.classList.toggle(klass.nav)
        })
      })
    }
  }

  /**
   * スクロール時の処理
   */
  scrollSetting() {
    const scrollEvent = () => {
      if (window.scrollY > 200) {
        this.body.classList.add(klass.scroll)
      } else {
        this.body.classList.remove(klass.scroll)
      }
    }

    scrollEvent()
    window.addEventListener('scroll', () => {
      scrollEvent()
    })
  }

  /**
   * スムーススクロール
   */
  smoothScrollSetting() {
    smoothscroll.polyfill()
    Array.from(document.querySelectorAll('.js-smooth-scroll')).forEach((link) => {
      link.addEventListener('click', (e) => {
        const ankerTarget = e.target
        e.preventDefault()

        this.body.classList.remove('view-nav')

        const targetId = ankerTarget.hash

        if (!document.querySelector(targetId)) {
          return false
        }

        const targetElement = document.querySelector(targetId)
        const rectTop = targetElement.getBoundingClientRect().top
        const offsetTop = window.pageYOffset
        const top = rectTop + offsetTop
        window.scrollTo({
          top,
          behavior: 'smooth',
        })
      })
    })
  }

  setInView() {
    inView('.inview').on('enter', (el) => {
      el.classList.add(klass.view)
    })
    inView.offset(100)
  }
}

export { InitSetting, klass }
