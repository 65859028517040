import Swiper from 'swiper/bundle'

const swiperSettings = () => {
  const worksGallery = new Swiper('[data-works-gallery]', {
    speed: 400,
    autoplay: false,
    loop: true,
    spaceBetween: 0,
    autoHeight: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  })
}

export { swiperSettings }
