import { LuminousGallery } from 'luminous-lightbox'

const lightboxSetting = () => {
  const luminousImages = document.querySelectorAll('.luminous-images')

  if (luminousImages.length > 0) {
    new LuminousGallery(luminousImages)
  }
}

export { lightboxSetting }
